:root {
  --max-width: 1100px;
  --nav-width: 1000px;
  --footer-width: 950px;
  --border-radius: 12px;
  --sm-radius: 8px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --foreground-rgb: 255, 255, 255;
  --background: #f4f4f4;
  --skeleton: rgba(197, 197, 197, 0.85);
  --border-color: #000000;
  --white-color: #ffffff00;
  --black-color: #000000;
  --backgroundPrice: #010101;
  --headColor: rgb(255, 0, 0);
  --phoneCall: #000;
  --phoneCallHover: #151515;
  --phoneBorder: #fff;
  --lunchSpan: rgb(255, 0, 0);
  --extra: rgb(255, 0, 0);
  --text-color: #ffffff;
  --boxShadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  --boxShadowOpactiy: 0px 0px 2px 2px rgba(0, 0, 0, 0.39);
  -webkit-boxshadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.75);
  -moz-boxshadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.75);
  /* Price */
  --price-tag-background: #d1d5db;
  --price-tag-height: 2rem;
}

.dark {
  --foreground-rgb: 255, 255, 255;
  --skeleton: rgba(85, 85, 85, 0.1);
  --background: #0b0707;
  --border-color: #0b0707;
  --white-color: #020202;
  --black-color: #ffffff;
  --headColor: rgb(255, 0, 0);
  --logo: rgb(255, 0, 0);
  --phoneCallHover: #e7e6e6;
  --phoneCall: #ffffff;
  --phoneBorder: #000000;
  --lunchSpan: rgb(255, 0, 0);
  --extra: rgb(255, 0, 0);
  --text-color: #8e8e8e;
  --boxShadowPhone: 0px 0px 9px 1px rgba(255, 255, 255, 0.75);
  -webkit-boxshadowphone: 0px 0px 9px 1px rgba(255, 255, 255, 0.75);
  -moz-boxshadowphone: 0px 0px 9px 1px rgba(255, 255, 255, 0.75);
  --boxShadow: 0px 0px 9px 2px rgba(255, 255, 255, 0.25);
  --boxShadowOpactiy: 0px 0px 4px 2px rgba(255, 255, 255, 0.75);
  -webkit-boxshadow: 0px 0px 9px 2px rgba(255, 255, 255, 0.75);
  -moz-boxshadow: 0px 0px 9px 2px rgba(255, 255, 255, 0.75);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 62.5%;
}
html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 25vh;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
body {
  color: var(--black-color);
  background: var(--background);
  counter-reset: section;
  font-size: 1.6rem;
  overflow-y: hidden;
}
/* :any-link {
  color: blue;
  text-decoration: underline;
}

:any-link:hover {
  color: red;
} */
a {
  color: inherit;
  text-decoration: none;
}
li {
  list-style: none;
}
button {
  border-style: none;
  color: inherit;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  color: var(--lunchSpan);
  cursor: pointer;
}
.dropdownItem {
  display: flex;
  margin: 10px auto;
}
.dropdownItem a {
  max-width: 300px;
  margin-left: 10px;
  transition: all;
}
.dropdown-content-container li {
  margin-bottom: 5px;
}
/* Define the shaking animation */
@keyframes shake {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-1px);
  }
  50% {
    transform: translateY(1px);
  }
  75% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply the shaking animation to the SVG when triggered */
.animate-svg {
  animation: shake 1s linear forwards alternate; /* Adjust duration and timing function as needed */
}

/* Your existing CSS styles */
#darkGroup {
  fill: green;
}
#lightGroup {
  fill: var(--lunchSpan);
}
#light,
#dark1,
#dark2 {
  transition: all 2s ease-in-out;
}
#light {
  opacity: 0.6;
}
#dark1,
#dark2 {
  opacity: 0;
}
#dark2 {
  transform: translateX(0%);
  opacity: 0;
}
#dark2 {
  transform: translateX(0%);
}
.svg {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.deliveryOption {
  cursor: pointer;
}

.svg:hover #light {
  transform: translateX(20px);
  opacity: 1;
  fill: green;
}
.svg:hover #dark1 {
  transform: translateX(150px);
  opacity: 1;
  fill: var(--lunchSpan);
}
.svg:hover #dark2 {
  transform: translateX(300px);
  opacity: 1;
  fill: var(--lunchSpan);
}
.input {
  padding: 5px;
  width: 100%;
  height: 60px;
  background: none;
  border: 4px solid var(--black-color);
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 26px;
  color: var(--black-color);
  opacity: 0.8;
  outline: none;
  transition: 0.5s;
  text-indent: 1rem;
}
/* .box:hover input {
  border-radius: 10px;
}
.box i {
  position: absolute;
  right: 15px;
  font-size: 16px;
  transition: 0.2s;
}
.box:hover i {
  opacity: 0;
  z-index: -1;
} */
.ing {
  display: flex;
  padding-inline: 0.4rem;
}
strong {
  font-size: 1.8rem;
  border: 2px solid var(--lunchSpan);
  margin-block: 1rem;
  padding: 1.4rem;
  rotate: 8deg;
}
/* Carousel */
/* .carousel {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--black-color);
  overflow-y: hidden;
}

::-webkit-scrollbar-thumb {
  height: 5px;
  overflow-y: hidden;
  width: 5px;
  background-color: var(--lunchSpan);
} */

.carousel img {
  width: 100%;
  aspect-ratio: 16/9;
  /* zoom: 0.8; */
  scroll-snap-align: center;
}
.table {
  font-size: clamp(1em, 1.8vw, 1.4em);
  flex-wrap: wrap;
  min-width: 315px;
  display: flex;
  margin-top: 20rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid var(--black-color);
  border-radius: 5px;
  margin-inline: 0.6em;
  margin-bottom: 1em;
}
.table h2 {
  color: var(--lunchSpan);
  padding-block: 1em;
  font-size: 3.2rem;
}
tbody {
  color: var(--black-color);
}
table,
td {
  width: 100%;
  height: 28px;
  flex-wrap: wrap;
  border: 1px solid var(--black-color);
  color: var(--black-color);
}
td {
  /* padding: 1.2rem; */
  word-break: auto-phrase;
  word-wrap: normal;
  width: -moz-fit-content;
  width: fit-content;
}
th {
  min-width: 100%;
  padding-left: 2px;
  flex-wrap: wrap;
  border: 1px solid var(--black-color);
}
tbody tr th {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
caption {
  text-decoration: underline;
  color: var(--black-color);
  padding: 1em;
}
.tablePara {
  color: var(--black-color);
  text-decoration: underline;
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}
.ingredients {
  word-break: break-all;
  word-wrap: break-word;
  font-size: clamp(0.8em, 1.4vw, 1.2em);
}
.theadTh {
  flex-wrap: wrap;
  word-break: break-all;
  word-wrap: break-word;
  font-size: clamp(0.65em, 1.4vw, 1.2em);
}

.navmenulist.active {
  right: 0;
}
.card {
  transform: translateX(0);
  animation-duration: 1500ms;
  animation-name: fadeIn;
  backface-visibility: hidden;
  border: 2px solid var(--black-color);
  border-radius: 4px;
}
@keyframes fadeIn {
  0% {
    transform: translateX(100%);

    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.card1 {
  margin: 10px 5px 10px 5px;
  border: 1px solid var(--black-color);
  border-radius: var(--sm-radius);
  transform: translateY(0);
  animation-duration: 1500ms;
  animation-name: slideIn;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 300px;
  margin-top: 20rem;
}
@keyframes slideIn {
  0% {
    transform: translateY(100%);

    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.cardTitle {
  display: flex;
  align-content: center;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  width: 100%;
  flex-direction: row;
  padding-inline: 1rem;
}

@media screen and (min-width: 768px) {
  .navmenubar {
    display: none;
  }
  .card {
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 360px;
    flex-wrap: wrap;
    flex-direction: column;
    flex-direction: row;
  }
  .navmenulist {
    flex-direction: row;
    min-height: fit-content;
    width: fit-content;
    column-gap: 24px;
    align-items: center;
  }
  .navlink::before {
    content: '';
    position: absolute;
    width: 0%;
    height: 6px;
    bottom: -16px;
    left: 0;
    background-color: black;
    transition: all 0.2s;
  }
  .navlink:hover:before {
    width: 100%;
  }
  .table {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: var(--max-width);
  }
  tbody tr th {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .ing {
    display: flex;
    max-width: 100%;
  }

  .table {
    width: 600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

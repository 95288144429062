.hidden {
  display: none;
}
.darkMode {
  cursor: pointer;
  border: 2px solid var(--lunchSpan);
  height: 36px;
  width: 36px;
  border-radius: var(--sm-radius);
  padding: 4px;
  position: fixed;
  margin-top: 8rem;
  left: 18px;
  z-index: 999;
  background-color: var(--black-color);
}

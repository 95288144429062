.logoShadow {
  /* background-color: red; */

  display: flex;
  /* background-color: red; */
  border-radius: 50%;
  box-shadow: var(--boxShadow);
  align-items: baseline;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0);
}
.nav {
  display: flex;
  position: fixed;
  top: 0;
  padding: 16px;
  align-items: stretch;
  transition: var(--background) 200ms, border 200ms;
  box-shadow: var(--boxShadow);
  width: 100%;
  max-width: var(--max-width);
  z-index: 888;
  align-content: center;
  flex-wrap: nowrap;
  font-size: 16px;
  justify-content: space-between;
  backdrop-filter: blur(10px);
  color: var(--black-color);
  background-color: var(--background);
}
.navbarHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.logo {
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-transform: uppercase;
  font-size: 12em;
  font-weight: 900;
}
.nav h2 {
  color: var(--lunchSpan);
  font-size: clamp(2.8rem, 1.6vw, 4.5rem);
}
.phoneCall {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  --phoneBorder: #fff;
  border: var(--lunchSpan) 2px solid;
  box-shadow: var(--boxShadowPhone);
}
.phoneCall:hover {
  background: var(--phoneCallHover);
  border: var(--phoneBorder) 2px solid;
  box-shadow: var(--boxShadowPhone);
}

/* 
.languageSwitcher {
  animation: rotation 1s cubic-bezier(0.25, 0.1, 0.25, 1); 
  filter: grayscale(50%); 
  transition: transform 0.5s ease;
  transform: scale(1.2); 
}

.languageSwitcher:hover,
.languageSwitcher:target,
.languageSwitcher:visited,
.languageSwitcher:focus,
.languageSwitcher:active {
  animation: rotation 1s cubic-bezier(0.25, 0.1, 0.25, 1); 
 
  filter: grayscale(0%); 
  transform: scale(1.2); 
}

.rotateLeft {
  transition: transform 1s ease; 
  transform: scale(1.2) rotateZ(-180deg); 
}

.rotateRight {
  transition: transform 1s ease; 
  transform: scale(1.2) rotateZ(180deg); 
}

@keyframes rotation {
  from {
    transform: rotateZ(0deg); 
  }
  to {
    transform: rotateZ(360deg); 
  }
} */

.btnWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.3rem;
  align-items: center;
}

.btnEng,
.btnSv {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  /* Adjusted border-radius */
  cursor: pointer;
  outline: none;
  border: 1px solid var(--black-color);
  padding: 6px;
  font-size: 1.2rem;
  transition: ease-in-out transform 0.3s;
  transform: translateZ(0); /* Prevent text from moving on hover */
}
.btnEng {
  border-radius: 2px 0 0 2px;
}
.btnSv {
  border-radius: 0 2px 2px 0;
}

.btnEng:active,
.btnEng:visited,
.btnEng:target,
.btnEng:focus {
  background-color: var(--black-color);
  color: var(--background);
  border: 1px solid var(--lunchSpan);
}

.btnEng:hover {
  transform: scale(1.1);
  box-shadow: var(--boxShadow);
}

.btnSv:active,
.btnSv:visited,
.btnSv:target,
.btnSv:focus {
  background-color: var(--black-color);
  color: var(--background);
  border: 1px solid var(--lunchSpan);
}

.btnSv:hover {
  box-shadow: var(--boxShadow);
  transform: scale(1.1);
}

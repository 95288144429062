.legendListWrapper {
  display: flex;
  flex-wrap: wrap;
}
.legenList {
  width: 50%;
  display: flex;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: stretch;
  color: var(--black-color);
  box-shadow: var(--boxShadow);
  box-shadow: var(-moz-boxshadow);
  box-shadow: var(-webkit-boxshadow);
  position: absolute;
  align-content: center;
  flex-wrap: wrap;
}
.grid {
  display: grid;
  border: 1px solid var(--black-color);
  grid-template-columns: repeat(2, minmax(50%, auto));
  align-content: center;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.cardOne {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
}
.card {
  border-radius: var(--border-radius);
  background: rgba(var(--background), 0);
  border: 1px solid rgba(var(--black-color), 0);
  transition: var(--background) 200ms, border 200ms;
  flex: 1;
}
.card p {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1.4;
  max-width: 30ch;
  padding-top: 1rem;
}
.card span {
  display: inline-block;
  transition: transform 200ms;
}
.span {
  font-size: 12px;
  font-weight: normal;
}
.swishSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  justify-content: space-between;
  flex-direction: column;
}
.swishContianer {
  display: flex;
}
.swishDesc {
  text-decoration: underline 2px var(--lunchSpan);
  font-weight: 600;
  padding-left: 2px;
}
.logoSwish {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--text-color);
  border-radius: 10px;
}
.tel {
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-left: 1rem;
}
.website {
  letter-spacing: 0.2rem;
}
.cardTitle {
  padding: 1.4rem;
  display: flex;
  color: var(--black-color);
  font-size: clamp(1.6rem, 1.8vw, 2.5rem);
  justify-content: center;
}
.copyRight {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-block: 8px;
  bottom: 0;
  left: 0;
  color: var(--black-color);
}
.map {
  width: 100%;
  height: 100%;
}
.fieldset ul li {
  list-style: none;
  padding-block: 0.2rem;
}
.fieldset li {
  line-height: 25px;
  display: flex;
  font-size: 1.25rem;
  text-align: justify;
  padding: 1rem;
  width: 100%;
  margin: 1rem;
  justify-content: flex-start;
}
.fieldset {
  display: flex;
  border: var(--lunchSpan) 2px solid;
  justify-content: space-around;
  align-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding: 0.6rem;
  margin: 0.6rem;
}
.fieldsetList li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fieldsetList .span {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.fieldsetList p {
  display: flex;
  align-content: flex-end;
  justify-content: center;
  font-size: 0.8rem;
  color: var(--lunchSpan);
}

/* Mobile */
@media (max-width: 700px) {
  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
    text-align: center;
  }
  .card h2 {
    margin-bottom: 0.5rem;
  }
}
/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .footer_cardOne {
    padding: 2rem;
  }
}
